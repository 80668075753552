import { ValidationMethod } from "@hydrantid/acm-client";
import { PrimaryRole } from "./primary-roles";

export interface FlattenedRoles {
    admin: boolean;
    auditor: boolean;
    requestor: boolean;
    domainValidator: boolean;
    customerManagement: boolean;
}

export const DEFAULT_ROLES = {
    admin: false,
    auditor: false,
    requestor: false,
    domainValidator: false,
    customerManagement: false,
};

export interface AuthUser {
    email?: string;
    email_verified?: boolean;
    sub?: string;
}

export interface RequiredPayloadItem {
    label: string;
    type: string;
}

export interface DomainValidator {
    id: string;
    capabilities: string[];
    methods: ValidationMethod[];
    name: string;
    requiredPayload: Record<string, RequiredPayloadItem>;
    whitelist?: string[];
}

export interface PagePermissions {
    accounts: boolean;
    cas: boolean;
    certificates: boolean;
    manageCertificates: boolean;
    dashboard: boolean;
    domains: boolean;
    help: boolean;
    logs: boolean;
    profile: boolean;
    requests: boolean;
    users: boolean;
    admin: boolean;
    tlsdiscovery: boolean;
    reports: boolean;
}

export const DEFAULT_PAGE_PERMISSIONS = {
    accounts: true,
    cas: false,
    certificates: false,
    manageCertificates: false,
    dashboard: false,
    domains: false,
    help: true,
    logs: false,
    profile: true,
    requests: false,
    users: false,
    admin: false,
    tlsdiscovery: true,
    reports: true,
};

export interface AppUser extends AuthUser {
    id: string | undefined;
    auth0UserId: string | undefined;
    name?: string;
    lastName?: string;
    firstName?: string;
    nickname?: string;
    picture?: string;
    updatedAt?: Date;
    primaryRole?: PrimaryRole;
    acceptedEula?: boolean;
    authSource?: string;
    authSourceType?: string;
    systemRoles: FlattenedRoles;
    accountAnyRoles: FlattenedRoles;
    accountRoles: Record<string, FlattenedRoles>;
    organizationAnyRoles: FlattenedRoles;
    organizationRoles: Record<string, FlattenedRoles>;
    validators: DomainValidator[];
}
