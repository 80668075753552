export enum PrimaryRole {
    SYSTEM_ADMIN = "System Admin",
    SYSTEM_AUDITOR = "System Auditor",
    ACCOUNT_ADMIN = "Account Admin",
    ACCOUNT_AUDITOR = "Account Auditor",
    ORGANIZATION_ADMIN = "Organization Admin",
    ORGANIZATION_AUDITOR = "Organization Auditor",
    REQUESTOR = "Requestor",
    DOMAIN_VALIDATOR = "Domain Validator",
    UNKNOWN = "Unknown",
    SERVICE_REQUESTOR = "Service Requestor",
    CUSTOMER_MANAGEMENT = "Customer Management",
}

export const SYSTEM_PRIMARY_ROLES = [
    PrimaryRole.SYSTEM_ADMIN,
    PrimaryRole.SYSTEM_AUDITOR,
    PrimaryRole.CUSTOMER_MANAGEMENT,
];

export const ACCOUNT_PRIMARY_ROLES = [
    PrimaryRole.ACCOUNT_ADMIN,
    PrimaryRole.ACCOUNT_AUDITOR,
    PrimaryRole.DOMAIN_VALIDATOR,
];

export const ORGANIZATION_PRIMARY_ROLES = [
    PrimaryRole.ORGANIZATION_ADMIN,
    PrimaryRole.ORGANIZATION_AUDITOR,
    PrimaryRole.REQUESTOR,
    PrimaryRole.SERVICE_REQUESTOR,
];
