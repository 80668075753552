import { Component, Inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { User, UsersService } from "@hydrantid/acm-client";
import { ReassignViewStore } from "./view-state";
import { MessageService } from "../../../../services/message/message.service";
import { FormatUserEmailPipe } from "../../../shared/pipes/format-user-email/format-user-email.pipe";

@Component({
    selector: "app-dialog-reassign-email",
    templateUrl: "./dialog-reassign-email.component.html",
    styleUrls: ["./dialog-reassign-email.component.scss"],
    providers: [ReassignViewStore, FormatUserEmailPipe],
})
export class DialogReassignEmailComponent {
    promptAssign = true;
    promptConfirm = false;

    form = new FormGroup({
        user: new FormControl<User | string>("", {
            nonNullable: true,
            validators: [Validators.required],
        }),
    });
    includeNotificationEmails = true;
    includeCertificates = new FormControl<boolean>(true);

    constructor(
        @Inject(MAT_DIALOG_DATA) public user: User,
        public viewStore: ReassignViewStore,
        private messageService: MessageService,
        private dialogRef: MatDialogRef<DialogReassignEmailComponent>,
        private usersService: UsersService,
        public formatUserEmailPipe: FormatUserEmailPipe,
    ) {
        this.viewStore.setUser(user);
        this.form.controls.user.valueChanges.subscribe((value) => {
            if (typeof value === "string") {
                this.viewStore.setTypeaheadEmail(value as string);
            }
        });
    }

    checkUser(): void {
        const user = this.form.controls.user.value;

        if (!user) {
            this.form.controls.user.setErrors({ selectFromList: false, required: true });
        } else if (typeof user === "string") {
            this.form.controls.user.setErrors({ selectFromList: true, required: false });
        } else {
            this.form.controls.user.setErrors(null);
        }
    }

    saveReassign(): void {
        const newUser = this.form.controls.user.value;
        if (newUser && typeof newUser === "object") {
            if (this.promptAssign) {
                this.promptConfirm = true;
                this.promptAssign = false;
                return;
            }
            this.usersService
                .usersIdExpiryEmailPost(this.user.id, {
                    newUserId: newUser.id,
                    shouldReassignAssignCerts: !!this.includeCertificates.value,
                })
                .subscribe(
                    (result) => {
                        if (this.messageService.showResult(result)) {
                            this.dialogRef.close();
                        }
                    },
                    (error) => this.messageService.showError(error.error),
                );
        }
    }

    disableForm(expiryEmailCount: number, certificatesCount: number): boolean {
        if (this.form.invalid) {
            return true;
        }

        if (!this.includeCertificates.value && expiryEmailCount === 0) {
            return true;
        }

        return expiryEmailCount === 0 && certificatesCount === 0;
    }

    get newOwnerEmail(): string {
        const user = this.form.controls.user.value;
        if (user && typeof user === "object") {
            return user.email ? user.email : "";
        }
        return user;
    }
}
